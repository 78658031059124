
/* Add a fade-in animation */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Add a slide-in animation */
  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Apply the fade-in and slide-in animations to the grid items */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  
    .item-container {
      animation: fadeIn 0.5s ease;
      // If you want slide-in effect, uncomment the line below:
      // animation: slideIn 0.5s ease;
  
      // Add vendor prefixes for cross-browser support
      -webkit-animation: fadeIn 0.5s ease;
      // -webkit-animation: slideIn 0.5s ease;
    }
  }
  
.portfolio-page .header{
  margin-left: 30px;
}



.portfolio-page .flat-button {
      /* Set the initial content to "Toggle Info" for desktop */
  &::before {
    content: "TOGGLE INFO";
  }

    cursor: pointer;
    align-self:end;
    height: 15px;
    color: #ffd700;
    font-size: 1em;
    font-weight: 400;
    // letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 5px 18px;
    border: 1px solid #ffd700;
    // margin: 35px 30px 0 0;
    // float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    // white-space: pre-wrap;
    

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }

  
.grid-container{
    --gap: 16px;
    --num-cols: 3;
    --row-height: 400px;
    box-sizing: border-box;
    padding: var(--gap);

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    // grid-auto-rows: var(--row-height);
    gap: var(--gap)
}

.grid-container img{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.grid-container-col-2{
    grid-column: span 2;
}

.grid-container-row-2{
    grid-row: span 2;
}

@media screen and (max-width: 1024px){
    .grid-container{
        --num-cols: 1;
        --row-height: 200px;
    }
}

.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;
    background-color: var(--color-bg);

    h1.page-title {
        margin-left: 20px;
        margin-top: 50px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
    @media(max-width: 920px){
        
        .flat-button {
            &::before {
                content: "TOGGLE";
              }
            padding: 3px 3px;
        }
        .header{
          margin-left: 0;
        }
    }
}