.error{
    display: flex;
    position: absolute;
    left: 30%;
    top: 30%;
    font-size: 3rem;
}

.bug{
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 30%;
    height: 30%;
}