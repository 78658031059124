.gallery-nav{
	max-width: 90%;
}
a{
    text-decoration: none;
}
.grid-filter-wrap {
	display: -ms-flexbox;
	display: flex;
	/* justify-content: space-between; */
	justify-content: center;
	width: 100%;
}

.grid-filter {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 3.5rem;
	list-style: none;
}

.grid-filter li {
	position: relative;
	width: 50%;
}

.grid-filter li a {
	display: block;
	position: relative;
	padding: 10px 20px;
	font-size: 0.9375rem;
	color: #666;
	text-align: center;
	border-radius: 2px;
}


.grid-filter li a:hover { color: red}

.grid-filter li.activeFilter a {
	color: #FFF;
	background-color: red;
	margin: 0;
	font-weight: bold;
}

.caps{
	text-transform: uppercase;
}



.flat-button2 {
	cursor: pointer;
	background: transparent;
	align-self:center;
	height: 15px;
	color: #ffd700;
	font-size: 1em;
	font-weight: 400;
	font-family: sans-serif;
	text-decoration: none;
	// padding: 20px 18px;
	margin: 5px 5px;
	border: 1px solid #ffd700;
	animation: fadeInAnimation 1s 1.8s backwards;
	// white-space: pre-wrap;
	

	&:hover {
		background: #ffd700;
		color: #333;
	}
}

.notification {
  background-color: #555;
  color: white;
  text-decoration: none;
  padding: 15px 26px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification:hover {
  background: red;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: red;
  color: white;
}