@import '../../../../node_modules/react-modal-video/scss/modal-video.scss';


// Variables for breakpoint sizes
$breakpoint-desktopXL: 1900px;
$breakpoint-desktop: 1200px;
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 620px;


// Styles for desktop XL (1900px and above)
@media (min-width: $breakpoint-desktopXL) {
  .content-details p.techTxt{
    font-size: 1.5em !important ;
    color: #7bc9ff
  }
  
  .content-details p.summary{
    font-size: 1.5em !important;
  }
}

// Styles for desktop (1200px and above)
@media (min-width: $breakpoint-desktop) and (max-width: ($breakpoint-desktopXL - 1)) {
  .content-details p.techTxt{
    font-size: 1em !important ;
    color: #7bc9ff
  }
  
  .content-details p.summary{
    font-size: 1.2em !important;
  }

}

// Styles for tablet (768px and above)
@media (min-width: $breakpoint-tablet) and (max-width: ($breakpoint-desktop - 1)) {
  // Add your styles here
  .content-details p.techTxt{
    font-size: 1em !important ;
    color: #7bc9ff
  }
  
  .content-details p.summary{
    font-size: 1em !important;
  }
}

// Styles for mobile (576px and above)
@media (min-width: $breakpoint-mobile) and (max-width: ($breakpoint-tablet - 1)) {
  // Add your styles here
  .content-details p.techTxt{
    font-size: 2em !important ;
    color: #7bc9ff
  }
  
  .content-details p.summary{
    font-size: 2em !important;
  }
}

// Styles for extra small screens (less than 576px)
@media (max-width: ($breakpoint-mobile - 1)) {
  // Add your styles here
  .content-details p.techTxt{
    font-size: 1em !important ;
    color: #7bc9ff
  }
  
  .content-details p.summary{
    font-size: 1em !important;
  }
}





.item-container .content-overlay {
    background: rgba(0,0,0,0.9);
    position: absolute;
    overflow: hidden;
    // padding: .5em 1em;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
  }


  .content-details {
    box-sizing: border-box;

    position: relative;
    text-align: left;
    padding-left: 2em;
    padding-right: 2em;
    width: 100%;
    top: 1em;
  }


  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    // text-transform: uppercase;
  }
  
  .content-details p{
    // color: #fff;
    font-size: 1rem !important;
    text-align: left;
  }

  .fadeIn-top{
    top: 20%;
  }


.viewItem{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.viewItem p{
    // text-decoration: underline;
    padding-right: 5px;
}

.iconBtn, .item-thumb{
    cursor:pointer;
    display: inline-block;
}

.circleIcon{
    font-size: 1.5rem;
    padding-right: 8px;
}

.modal-video-body{
    max-width: 1280px;
}

.description-container h2{
    // display: inline-block;
}

.description-container{
  overflow: hidden;
}

.linkIcons{
  position: relative;
}


// .desc-title{
//     display: flex;
//     flex-direction: row;
//     align-items: center;
    
// }

.desc-type-text{
    color: #8c8c8c;
    // padding-top: 8px;
    font-size: .8rem;
}

.lrg-text{
    font-size: 1.2rem;
    // padding: 0;
    color: white;
}

.linkIcons{
    justify-self: end;
    font-size: 1.5rem
}

.item-container{
    border: #205171 1px solid;
    // background-color: #134466;
}


.description-container{
    // border: #194562 1px solid;
    display: grid;
    box-sizing: border-box;
     grid-template-columns: 3fr 1fr;
    //  background-color: #134466;
    align-items: center;
    padding: 5px 20px;
    z-index: 3;
}


.btn {
    background-color: var(--background-color);
    // color: #222;
    padding: .5em 1em;
    border: none;
    outline: none;
    position: relative;
    // cursor: pointer;
  
    --background-color: #103c5a;
    --border-size: 1px;
    --accent-color: #0AF;
  }

  
.btn.btn-border-pop::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border: var(--border-size) solid var(--color-primary);
    transition: top, left, right, bottom, 100ms ease-in-out;
  }
  
  .btn.btn-border-pop:hover::before,
  .btn.btn-border-pop:focus::before {
    top: calc(var(--border-size) * -2);
    left: calc(var(--border-size) * -2);
    right: calc(var(--border-size) * -2);
    bottom: calc(var(--border-size) * -2);
  }

  .ribbon-1 {
    position: fixed;
    background: #08769b;
    box-shadow: 0 0 0 999px #08769b;
    clip-path: inset(0 -100%);
  }
  .left {
    inset: 0 auto auto 0;
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
  }
  .right {
    inset: 0 0 auto auto;
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
  }
  
  .ribbon-2 {
    font-size: 1.2em;
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 10px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: purple;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  }
  
