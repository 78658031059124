.logo-container {
  display: flex;
  justify-content: flex-start;
  // background-color: beige;
  // z-index: 0;
  // width: 400px;
  // height: 609px;
  // opacity: 1;
  // position: absolute;
  // top: 50px;
  // right: 15%;
  // bottom: 0;
  // left: auto;
  // margin: auto;


  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    // transform: rotateZ(20deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 130%;
    opacity: 1;
   animation: rotateClockwise 50s linear infinite;
    // transform: rotateZ(12deg) !important;
    z-index: 1;
  }
}

@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}
