html {
  /* font-size: 62.5%; */
}

:root{
  --color-bg: #022c43;
  --color-primary: #ffd700;
}

a:link, a:visited {
  color: var(--color-primary);
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #ffd700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-bg);
  overflow: hidden;
  display: block;
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}