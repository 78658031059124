#video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  filter: opacity(10%);
  object-fit: cover; /* This ensures the video covers the screen while maintaining its aspect ratio */
}


section.home-page{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: 100vh;


  .text-zone{
    // background-color: green;
    width: 45%;
    flex-shrink: 0 1 auto;
      padding-left: 150px;

  }

  h1 {
    color: #fff;
    font-size: 4.5em;
    line-height: 1.2em;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      // left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .subText{
    color: #8d8d8d;
  }

  .flat-button {
    display: inline-block;
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    animation: fadeInAnimation 1s 1.8s backwards;

      &:hover {
        background: #ffd700;
        color: #333;
      }
    }

    .subtext-container{
    // background-color: blue;
    margin-top: 20px;
    }

  .svgArt{
    width: 55%;
    align-items: flex-start;
    margin-top: 0;
    // background-color: blue;
  }

  .buttonRow{
    display: flex;
  }
  .buttonRow .flat-button {
    // display: inline-block; /* This makes sure they line up horizontally */
    margin-right: 20px; /* Adjust this value to increase or decrease the space between the buttons */
  }
  
  .buttonRow .flat-button:last-child {
    margin-right: 0; /* Removes margin from the last button to avoid extra space at the end */
  }

}


@media screen and (max-width: 500px) {
  section.home-page{
    flex-direction: column;
    // padding: 20px;
    margin-top: 50px;
    

    h1{
       font-size: 3.5em;
    }
    .text-zone{
      // width: 100%;
      width: fit-content;
      padding-left: 20px;
    }

    .svgArt{
      // width: 100%;
      width: fit-content;
    }
    .buttonRow{
      flex-direction: column;
    }
    .buttonRow .flat-button {
      // display: inline-block; /* This makes sure they line up horizontally */
      margin-right: 0;
      margin-bottom: 10px; /* Adjust this value to increase or decrease the space between the buttons */
    }
    
    .buttonRow .flat-button:last-child {
      margin-right: 0; /* Removes margin from the last button to avoid extra space at the end */
    }

  }


  h1{
    font-size: 2rem;
  }
}