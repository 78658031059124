/* VideoModal.scss */
.modal-video {
  // height: 100vh;
  background-color: rgba(0, 0, 0, 0.8)

}


@media (max-width: 900px) {
  .modal-video {
   height: 100vh !important;
  
  }

}

